import { Link } from "react-router-dom";
import styles from "./TheBrand.module.scss";
const TheBrand = ({ img }) => {
  return (
    <div className={styles.section}>
      <div className={styles.section__text}>
        <h2>Reviving the Timeless Elegance of Beachwear Aesthetics</h2>
        <p>
          Known for its chic silhouettes and modern swimwear designs, Diango
          Choix offers a unique blend of style and sophistication.
        </p>
        <p>
          Inspired by the allure of supermodels' vacation looks, we curate
          limited-release collections that embody timeless elegance. By
          combining the finest Italian lycra with enduring designs, our swimwear
          ensures your summer remains eternally fashionable.
        </p>
        <Link to="/collection">
          <button>HIGH SUMMER '23 </button>
        </Link>
      </div>
      <img src={img} alt="" />
    </div>
  );
};

export default TheBrand;
