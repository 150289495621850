import { Link } from "react-router-dom";
import styles from "./Header.module.scss";
const Header = ({ image, showNavigation, applyImgPosition }) => {
  return (
    <div className={styles.section}>
      <img
        src={image}
        alt=""
        style={{
          objectPosition: applyImgPosition ? "88%" : "initial",
        }}
      />
      {showNavigation && (
        <div className={styles.section__text}>
          <h2>DIANGO CHOIX LATEST COLLECTION</h2>
          <div className={styles.section__text__navigation}>
            <Link to="/collection" style={{ color: "inherit" }}>
              <p>swim collection</p>
            </Link>
            <Link to="/collection" style={{ color: "inherit" }}>
              <p>dress collection</p>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
