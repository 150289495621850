import styles from "./Campaign.module.scss";

const Campaign = () => {
  return (
    <div className={styles.section}>
      <div className={styles.section__box}>
        <div className={styles.section__box__text}>
          {/* <h1>A Story with Diango Choix</h1>
          <button>DISCOVER</button> */}
        </div>
      </div>
    </div>
  );
};

export default Campaign;
