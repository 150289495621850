import emailjs from "@emailjs/browser";
import { useRef, useEffect, useState } from "react";
import styles from "./MailingList.module.scss";
import Alert from "@mui/material/Alert";

const MailingList = () => {
  const form = useRef();
  const [alert, setAlert] = useState(false);
  const [status, setStatus] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_gb2scnt",
        "template_9u2g8mr",
        form.current,
        "cAfrXfBn2dYIe-_we"
      )
      .then(
        (success) => {
          setAlert(success);
        },
        (error) => {
          setStatus(error);
        }
      );
    e.target.reset();
  };

  useEffect(() => {
    setTimeout(() => {
      setAlert(false);
      setStatus(false);
    }, 1500);
  }, [alert]);

  return (
    <div className={styles.section}>
      <div className={styles.section__heading}>
        <h2>WORLD OF DIANGO CHOIX</h2>
        <div className={styles.section__heading__text}>
          <p>For exclusive updates, event's and promotions.</p>
          <form
            className={styles.section__heading__text__form}
            ref={form}
            onSubmit={sendEmail}
          >
            <input
              type="text"
              placeholder="Enter your instagram username"
              name="username"
            />
            <button type="submit">JOIN</button>
            {alert && (
              <Alert
                severity="success"
                className={`${styles.section__alert} ${
                  alert
                    ? styles["section__alert--active"]
                    : styles["section__alert--not-active"]
                }`}
              >
                E-mail succesfully sent!
              </Alert>
            )}
            {status && (
              <Alert
                severity="error"
                className={`${styles.section__alert} ${
                  status
                    ? styles["section__alert--active"]
                    : styles["section__alert--not-active"]
                }`}
              >
                An error occurred, try again later!
              </Alert>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default MailingList;
