import styles from "./Footer.module.scss";
import { Link } from "react-router-dom";

const Footer = ({ logoWhite }) => {
  return (
    <div className={styles.section}>
      <Link to="/" id={styles.mobileChevron}>
        <img src={logoWhite} alt="" />
      </Link>
      <div className={styles.section__up}>
        <div className={styles.section__up__navigation}>
          <div className={styles.section__up__navigation__item}>
            <Link
              to="/collection"
              id={styles.mobileChevron}
              style={{ color: "white" }}
            >
              <h4>Collection</h4>
            </Link>
          </div>
          <div className={styles.section__up__navigation__item}>
            <Link
              to="/the-brand"
              id={styles.mobileChevron}
              style={{ color: "white" }}
            >
              <h4>The Brand</h4>
            </Link>
          </div>
          <div
            className={styles.section__up__navigation__item}
            style={{ color: "white" }}
          >
            <a
              href="https://instagram.com/diangochoix?igshid=MmJiY2I4NDBkZg=="
              id={styles.mobileChevron}
              style={{ color: "white" }}
            >
              <h4>Contact Us</h4>
            </a>
          </div>
        </div>
        <div className={styles.section__up__icons}>
          <div className={styles.iconWrapper}>
            <a href="https://instagram.com/diangochoix?igshid=MmJiY2I4NDBkZg==">
              <i
                className="fa-brands fa-instagram"
                style={{ color: "white" }}
              ></i>
            </a>
          </div>
          <div className={styles.iconWrapper}>
            <i className="fa-brands fa-tiktok"></i>
          </div>
        </div>
      </div>
      <hr />
      <div className={styles.section__down}>
        <p>Copyright © 2023 Diango Choix</p>
      </div>
    </div>
  );
};

export default Footer;
