import { useEffect } from "react";
import styles from "./CollectionItem.module.scss";
const CollectionItem = ({
  itemImage,
  itemName,
  isActive,
  setIsActive,
  handleClick,
}) => {
  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleOutsideClick = (e) => {
    if (!e.target.closest(`.${styles.section}`)) {
      setIsActive(null); // Deactivate the image when user clicks outside
    }
  };
  return (
    <div className={styles.section} id={isActive ? `${styles.activeItem}` : ""}>
      <div
        id={
          isActive ? `${styles.imageWrapperActive}` : `${styles.imageWrapper}`
        }
      >
        <img src={itemImage} alt="item" onClick={handleClick} />
      </div>
      <h3>{itemName}</h3>
      <a href="https://instagram.com/diangochoix?igshid=MmJiY2I4NDBkZg==">
        <button id={isActive ? `${styles.buttonActive}` : `${styles.button}`}>
          REQUEST ORDER
        </button>
      </a>
    </div>
  );
};

export default CollectionItem;
