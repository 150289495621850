import styles from "./CarouselComponent.module.scss";

const CarouselComponent = ({ img1, img2, img3, img4, img6, img7 }) => {
  return (
    <div className={styles.section}>
      <div className={styles.section__slider}>
        <img src={img1} alt="" />
        <img src={img2} alt="" />
        <img src={img3} alt="" />
        <img src={img4} alt="" />
        <img src={img6} alt="" />
        <img src={img7} alt="" />
      </div>
    </div>
  );
};

export default CarouselComponent;
