import { useState } from "react";
import styles from "./Collection.module.scss";
import CollectionItem from "./CollectionItem/CollectionItem";

const Collection = ({ items, heading }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleItemClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className={styles.section}>
      <h2>{heading}</h2>
      <div className={styles.desktop}>
        <div className={styles.section__wrapper}>
          <div className={styles.section__collection}>
            {items.map((item, index) => {
              if (index <= 2) {
                return (
                  <CollectionItem
                    itemImage={item.itemImage}
                    itemName={item.itemName}
                    setIsActive={setActiveIndex}
                    isActive={activeIndex === index}
                    handleClick={() => handleItemClick(index)}
                  />
                );
              }
            })}
          </div>
          <div className={styles.section__collection}>
            {items.map((item, index) => {
              if (index >= 3) {
                return (
                  <CollectionItem
                    itemImage={item.itemImage}
                    itemName={item.itemName}
                    isActive={activeIndex === index}
                    setIsActive={setActiveIndex}
                    handleClick={() => handleItemClick(index)}
                  />
                );
              }
            })}
          </div>
        </div>
      </div>
      <div className={styles.mobile}>
        <div className={styles.section__wrapper}>
          <div className={styles.section__collection}>
            {items.map((item, index) => {
              if (index <= 1) {
                return (
                  <CollectionItem
                    itemImage={item.itemImage}
                    itemName={item.itemName}
                    isActive={activeIndex === index}
                    setIsActive={setActiveIndex}
                    handleClick={() => handleItemClick(index)}
                  />
                );
              }
            })}
          </div>
          <div className={styles.section__collection}>
            {items.map((item, index) => {
              if (index >= 2 && index <= 3) {
                return (
                  <CollectionItem
                    itemImage={item.itemImage}
                    itemName={item.itemName}
                    isActive={activeIndex === index}
                    setIsActive={setActiveIndex}
                    handleClick={() => handleItemClick(index)}
                  />
                );
              }
            })}
          </div>
          <div className={styles.section__collection}>
            {items.map((item, index) => {
              if (index >= 4 && index <= 5) {
                return (
                  <CollectionItem
                    itemImage={item.itemImage}
                    itemName={item.itemName}
                    isActive={activeIndex === index}
                    setIsActive={setActiveIndex}
                    handleClick={() => handleItemClick(index)}
                  />
                );
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Collection;
