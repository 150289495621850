import styles from "./TextCenter.module.scss";
const TextCenter = ({ text }) => {
  return (
    <div className={styles.section}>
      <p>{text}</p>
    </div>
  );
};

export default TextCenter;
