import { useState, useEffect, useRef } from "react";
import styles from "./Navbar.module.scss";
import { Link } from "react-router-dom";
const Navbar = ({ logoWhite, logoBlack }) => {
  const [isActive, setIsActive] = useState(false);
  const [hide, setHide] = useState(false);
  const [mobileNavigationIcon, setMobileNavigationIcon] = useState(true);
  const mobileMenuRef = useRef(null);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    if (window.scrollY === 0 && mobileNavigationIcon == true) {
      setIsActive(false);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [mobileNavigationIcon, isActive]);

  const handleScroll = () => {
    if (window.scrollY > 400) {
      setHide(true);
    }
    if (window.scrollY < 400 && window.scrollY > 0) {
      setHide(false);
      setIsActive(true);
    }
    if (window.scrollY === 0) {
      if (mobileNavigationIcon) {
        setIsActive(false);
      } else {
        setIsActive(true);
      }
    }
  };

  function handleMouseEnter() {
    setIsActive(true);
  }

  function handleMouseLeave() {
    setIsActive(false);
  }

  function handleMobileNavigationIconClick() {
    setMobileNavigationIcon(!mobileNavigationIcon);
    if (isActive == false) {
      setIsActive(true);
    }
    if (mobileNavigationIcon) {
      mobileMenuRef.current.style.display = "flex";
    }
    if (!mobileNavigationIcon) {
      mobileMenuRef.current.style.display = "none";
    }
  }

  function handleMobileNavigationLinkClick() {
    mobileMenuRef.current.style.display = "none";
    setMobileNavigationIcon(true);
  }

  return (
    <div
      className={
        isActive ? `${styles.section} ${styles.active}` : `${styles.section}`
      }
      id={hide ? `${styles.hide}` : ""}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={styles.section__mobile}>
        {mobileNavigationIcon ? (
          <i
            className={`fa-solid fa-bars ${
              !isActive
                ? styles.section__mobile__bars
                : styles.section__mobile__bars__active
            }`}
            onClick={handleMobileNavigationIconClick}
          ></i>
        ) : (
          <i
            className={`fa-regular fa-x ${styles.section__mobile__x}`}
            onClick={handleMobileNavigationIconClick}
          ></i>
        )}
      </div>

      <div className={styles.section__logo}>
        {isActive ? (
          <Link to="/" id={styles.mobileChevron}>
            <img src={logoBlack} alt="" />
          </Link>
        ) : (
          <Link to="/" id={styles.mobileChevron}>
            <img src={logoWhite} alt="" />
          </Link>
        )}
      </div>

      <div className={styles.section__navigation} id={styles.navigationDesktop}>
        <Link to="/" id={styles.mobileChevron}>
          <div className={styles.section__navigation__item}>
            <h4>main</h4>
          </div>
        </Link>
        <Link to="/collection" id={styles.mobileChevron}>
          <div className={styles.section__navigation__item}>
            <h4>collection</h4>
          </div>
        </Link>
        <Link to="/the-brand" id={styles.mobileChevron}>
          <div className={styles.section__navigation__item}>
            <h4>the brand</h4>
          </div>
        </Link>
      </div>
      <div
        className={styles.section__navigation}
        ref={mobileMenuRef}
        id={styles.navigationMobile}
      >
        <Link
          to="/"
          id={styles.mobileChevron}
          onClick={() => handleMobileNavigationLinkClick()}
        >
          <h4>main</h4>
          <i className="fa-solid fa-chevron-right"></i>
        </Link>
        <Link
          to="/collection"
          id={styles.mobileChevron}
          onClick={() => handleMobileNavigationLinkClick()}
        >
          <h4>collection</h4>
          <i className="fa-solid fa-chevron-right"></i>
        </Link>
        <Link
          to="/the-brand"
          id={styles.mobileChevron}
          onClick={() => handleMobileNavigationLinkClick()}
        >
          <h4>the brand</h4>
          <i className="fa-solid fa-chevron-right"></i>
        </Link>
      </div>
    </div>
  );
};

export default Navbar;
