import { Routes, Route } from "react-router-dom";

import styles from "./App.module.scss";

import Collection from "./components/Collection/Collection";
import ImageTextCenter from "./components/ImageTextCenter/ImageTextCenter";
import Navbar from "./components/Navbar/Navbar";
import TheBrand from "./components/TheBrand/TheBrand";
import MailingList from "./components/MailingList/MailingList";
import Campaign from "./components/Campaign/Campaign";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import TextCenter from "./components/TextCenter/TextCenter";
import CarouselComponent from "./components/CarouselComponent/CarouselComponent";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";

import auretaMidiZoomed from "./images/auretaMidiZoomed.jpeg";
import pecina from "./images/pecina (1).jpeg";
import essentialsImg from "./images/whiteRockMNE.jpeg";
import img2 from "./images/c2.jpeg";
import img3 from "./images/c3 (1).jpeg";
import img4 from "./images/c4.jpeg";
import img5 from "./images/c5.jpeg";
import img6 from "./images/c6.jpeg";
import logoWhite from "./images/1-removebg-preview.png";
import logoBlack from "./images/blackLogo.svg";
import aliceCutDetailBlack from "./images/whiteSeaMNE.jpeg";
import aliceCutDetailColor from "./images/aliceCutDetailColor.jpeg";
import auretaMidiBlack from "./images/yellowRockMNE.jpeg";
import miniBlackDress from "./images/miniBlackDress.jpeg";
import rivieraSet from "./images/greenTreeMNE.jpeg";
import newHeaderImg from "./images/HeaderImgMNE.jpeg";
import boxes from "./images/boxes.jpeg";
import boxes1 from "./images/kutije1.jpeg";
import wallpaper from "./images/newPoster.jpeg";
import collectionImage1 from "./images/collectionImg1.jpeg";
import blackGrass from "./images/blackGrassMNE.jpeg";
import knitDress from "./images/knit.jpeg";
import nightRegent from "./images/nightRegent.jpeg";
import good2 from "./images/good2.jpeg";
import model4 from "./images/blackGood.jpeg";
import swimModel5 from "./images/regentGreen (1).jpeg";
import swimModel6 from "./images/blueNewPool.jpeg";

function App() {
  const items1 = [
    { itemImage: aliceCutDetailColor, itemName: "alice cut detail" },
    { itemImage: swimModel5, itemName: "nala swim" },
    { itemImage: pecina, itemName: "bloom cut-out" },
    { itemImage: aliceCutDetailBlack, itemName: "amelia swim" },
    { itemImage: model4, itemName: "aureta two-piece" },
    { itemImage: swimModel6, itemName: "alice two-piece" },
  ];
  const items2 = [
    { itemImage: auretaMidiBlack, itemName: "amelia dress" },
    { itemImage: miniBlackDress, itemName: "mini dress" },
    { itemImage: blackGrass, itemName: "amelia dress" },
    { itemImage: knitDress, itemName: "amalfi dress" },
    { itemImage: auretaMidiZoomed, itemName: "Aureta Midi dress" },
    { itemImage: good2, itemName: "riviera set" },
  ];

  const textCollection =
    "The new Diango Choix SS23 Collection presents clothing rich in sensuality and elegant detail. A timeless style created for a unique woman, a modern diva.";

  return (
    <>
      <Navbar logoWhite={logoWhite} logoBlack={logoBlack} />
      <ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={
            <div className={styles.pageWrapper}>
              <Header
                image={newHeaderImg}
                showNavigation={true}
                applyImgPosition={true}
              />
              <ImageTextCenter
                image={essentialsImg}
                text={"Diango Choix Essentials"}
                buttonText={"DISCOVER"}
              />
              <TheBrand img={boxes1} />
              <MailingList />
              <CarouselComponent
                img1={nightRegent}
                img2={img2}
                img3={img3}
                img4={img4}
                img5={img5}
                img6={img6}
              />
            </div>
          }
        />
        <Route
          path="/the-brand"
          element={
            <div className={styles.theBrandPageWrapper}>
              <TheBrand img={boxes} />
            </div>
          }
        />
        <Route
          path="/collection"
          element={
            <div className={styles.pageWrapper}>
              <h1 className={styles.collectionHeading}>
                DIANGO CHOIX COLLECTION SS23
              </h1>
              <Header image={collectionImage1} showNavigation={false} />
              <Collection heading="SWIM ESSENTIAL COLLECTION" items={items1} />
              <TextCenter text={textCollection} />
              <Header image={rivieraSet} showNavigation={false} />
              <Collection heading="DRESS COLLECTION" items={items2} />
            </div>
          }
        />
        <Route path="/campaigns" element={<Campaign />} />
      </Routes>
      <Footer logoWhite={logoWhite} />
    </>
  );
}

export default App;
