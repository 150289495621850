import { Link } from "react-router-dom";
import styles from "./ImageTextCenter.module.scss";
const ImageTextCenter = ({ image, text, buttonText }) => {
  return (
    <div className={styles.section}>
      <img src={image} alt="" />
      {text && (
        <div className={styles.section__text}>
          <h2>{text}</h2>
          <Link to="/collection">
            <button> {buttonText}</button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default ImageTextCenter;
